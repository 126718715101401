import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dayDiff'
})

@Injectable()
export class DayDiffPipe implements PipeTransform {
    transform(secondDate) {
        if(secondDate == "" || secondDate == null || secondDate == "undefined"){
            return "";
        }else{
            var date2 = new Date(secondDate);
            var date1 = new Date();
            var timeDiff = date2.getTime() - date1.getTime();
            var diffDays = Math.abs(Math.floor(timeDiff / (1000 * 3600 * 24)));
            return diffDays;
        }
    }
}