import { Pipe, PipeTransform } from '@angular/core';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
@Pipe({ name: 'menuView' })
export class menuView implements PipeTransform {
    privileges:any;
    transform(menuItem: any): boolean {
        this.privileges=JSON.parse(localStorage.getItem('currentUser')).privileges;
        if(this.privileges.pipe(filter(menu => menu['name'] == menuItem)) )
            return true; 
        else
            return false;
      }
}