
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByDatePipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
        if (!array) {
            return [];
        }
        array.sort((a: any, b: any) => {
            if (a[field] === '' || b[field] === '' ||
                a[field] === null || b[field] === null ||
                typeof a[field] === 'undefined' || typeof b[field] === 'undefined') {
                return 0;
            }
            return this.getTime(new Date(a[field])) - this.getTime(new Date(b[field]));
        });
        return array;
    }

    private getTime(date?: Date) {
        return date != null ? date.getTime() : 0;
    }
}
