import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    profile: any;
    token = JSON.parse(localStorage.getItem('currentUser')).token;
    companyID = JSON.parse(localStorage.getItem('currentUser')).companyID;
    headers = new HttpHeaders({
        'companyID': this.companyID,
        'Authorization': 'token ' + this.token,

    });
    constructor(
        private http: HttpClient
    ) { }
    getRoles() {
        return this.http.get(`${environment.apiUrl}/auth/roles`, { headers: this.headers })
    }

    getmatch(obj) {
        this.profile = JSON.parse(localStorage.getItem('currentUser'));
        if (this.profile.extras.psName) {
            obj.psID = this.profile.extras.psID;
            obj.subDivID = this.profile.extras.subDivID;
            obj.circleID = this.profile.extras.circleID;
        }
        else if (this.profile.extras.circle) {
            obj.circleID = this.profile.extras.circleID;
            obj.subDivID = this.profile.extras.subDivID;
        }
        else if (this.profile.extras.subDivision) {
            obj.subDivID = this.profile.extras.subDivID;
        }
        else {
            // obj.level = 'admin';
        }
        return obj;
    }
}