
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sumByColumn'
})
export class SumByColumnPipe implements PipeTransform {
    transform(array: any[], field: string): any {
        if (!array) {
            return 0;
        }
        let total = 0;
        array.forEach(item => {
            total = total + parseInt(item[field])
        });
        return total;
    }
}
