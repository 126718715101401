import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete',
  templateUrl: './confirmDelete.component.html'
})

export class ConfirmDeleteComponent implements OnInit {

  @Input('msg') msg;
  @Input('buttonName') buttonName;
  @Input('title') title;

  @Output() event = new EventEmitter<any>();

  ngOnInit() {
  }
  delete() {
    this.event.emit(true)
  }
  hideModal() {
    this.event.emit(false)
  }
}