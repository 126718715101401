import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { sharedPrimengModule } from './shared/shared-primeng.module';
import { sharedModule } from '../app/shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HomeComponent } from './home/home.component';
const config: SocketIoConfig = { url: 'wss://eraksha-api.trakitnow.com', options: {} };
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularMaterialModule,
    FlexLayoutModule,
    sharedPrimengModule,
    sharedModule,
    // ToastrModule.forRoot({timeOut: 10000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true}),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBkKs4rGe4OJ9lVGxa94andW0DjCXlBBy4',
      libraries: ['places', 'geometry', 'drawing']
    }),
    SocketIoModule.forRoot(config)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
