import { Component, OnInit, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-nav',
    templateUrl: './breadcrumb.component.html'
    // styleUrls: ['./.component.scss']
})
export class NavComponent implements OnInit {
    @Input('Parent') Parent;
    @Input('pLink') pLink;
    @Input('child') child;
    constructor() { }

    ngOnInit(): void { }
}
