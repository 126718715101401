import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MastersService } from 'src/_services/masters.service';
@Component({
  selector: 'app-confirmInfo',
  templateUrl: './confirmwindow.component.html',
  providers: [MessageService, ConfirmationService]
})
export class ConfirmWindowComponent implements OnInit {
  devicestock: any;
  stock: any;
  modelList: any;
  filterCriteria: any;
  variantList: any;
  selectedItem: any;
  bsValue: any;
  constructor(private _confirmationService: ConfirmationService, private _masterService: MastersService, private _toast: MessageService) { }
  @Output() openConfirmationWindow = new EventEmitter();
  ngOnInit() {

  }
  confirm() {
    this.openConfirmationWindow.emit(this.selectedItem);
    this._confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        //Actual logic to perform a confirmation
      }
    });
  }
}
