import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/_services/common.service';
@Component({
    selector: 'app-user',
    templateUrl: './sharedUser.component.html',
    // styleUrls: ['./user.component.scss'],
    providers: [MessageService]
})
export class SharedUserComponent implements OnInit {
    rolesList: any;
    loading: boolean;
    searchText:any;
    roleCodes:any;
    obj: any;
    obj1: any = {};
    constructor(
        private _toast: MessageService,
        private common: CommonService
    ) { }
    @Output() getReportsByUser = new EventEmitter();
    ngOnInit(): void {
        this.getRoles();
        this.roleCodes = "";
    }
    showMessage(type,msg) {
        this._toast.add({severity:type,summary:msg})
      }
    getRoles() {
        this.common.getRoles().subscribe(
            data => {
                if(data['status'] =='success') {
                  this.rolesList = data['rows'];
                  this.loading = false;
                } else {
                  this.loading =  false;
                  this.showMessage('warn','Error while retrieving User logs list. Please try again.');
                  return
                }
                
              },
              error => {
                this.loading = false;
                    this.showMessage('warn', 'Network error, please try again after sometime.');
              }
        )
    }
    getReports() {
        
        if(this.searchText != undefined && this.searchText !='') {
            this.obj1.searchText = this.searchText;
        }
        if(this.roleCodes) {
            this.obj1.roleCodes = this.roleCodes;
        }
        this.getReportsByUser.emit(this.obj1)
    }
    clear() {
       this.obj1 = {}
        this.getReportsByUser.emit(this.obj1)
    }
}
