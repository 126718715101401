import { Component, OnInit, Input } from '@angular/core';

import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs'

@Component({
    selector: 'app-mapLastLoc',
    templateUrl: './mapLastLoc.component.html',
    providers: [MessageService]
})
export class MapLastLocComponent implements OnInit {

    private _locationobj = new BehaviorSubject([]);
    @Input()
    set locationobj(value) {
        this._locationobj.next(value);
    };
    get locationobj() {
        return this._locationobj.getValue();
    }

    lat: any;
    lng: any;
    markerTitle: any;

    constructor(private _toast: MessageService) { }

    ngOnInit() {
        this._locationobj.subscribe(data => {
            if (data) {
                this.lat = data['lat'];
                this.lng = data['lng'];
                this.markerTitle = data['markerTitle'] ? data['markerTitle'] : 'Marker';
            }
        }, error => {
            this._toast.add({ severity: 'error', summary: 'Error while loading Map. Please try again.' })
        })
    }

}