
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'uppercase'
})
export class UpperCasePipe implements PipeTransform {
    transform(field: string): any {
        if (!field) {
            return field;
        }
        return field.toUpperCase();
    }
}
