import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inlakhs'
})

@Injectable()
export class InLakhsPipe implements PipeTransform {
    transform(input) {
        var exp, rounded;
        if (isNaN(input)) {
            return null;
        }
        if (input < 100000) {
            return (input / 100000);
        }
        exp = Math.floor(Math.log(input) / Math.log(100000));
        return (input / Math.pow(100000, exp)).toFixed(2);
    }
}