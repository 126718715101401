import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap';
import { NumberOnlyDirective } from './numberonly.directive';
import { TextOnlyDirective } from './textonly.directive';
import { sharedNgModule } from './shared-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { AgmCoreModule } from '@agm/core';
// import { LeafletDrawModule } from '@asymmetrik//ngx-leaflet-draw';
// import { LeafletModule } from '@asymmetrik//ngx-leaflet';

import { menuView } from '../shared/menuview.pipe';
import { ArraySortPipe } from '../shared/sorting.pipe';
import { SortGridPipe } from '../shared/sortdata.pipe';
import { FilterPipe } from '../shared/searchfilter.pipe';
import { ToSelectItemPipe } from '../shared/selectItem.pipe';
import { NavComponent } from './breadcrumb.component';
import { ConfirmDeleteComponent } from './confirmDelete.component';
import { ConfirmWindowComponent } from '../shared/confirmwindow.component';
import { EqualValidator } from '../shared/equql-validator.directive';
import { AlphaNumericDirective } from '../shared/alphanumericdirective';
import { FormatTimePipe } from './formattime.directive';
import { CommaNumberOnlyDirective } from './commaNumberOnly.directive'
import { MakeWordsPipe } from './makeWords.pipe';
import { CurrencyCodePipe } from './currencyCode.pipe';
import { sharedPrimengModule } from './shared-primeng.module';
import { DayDiffPipe } from './dayDiff.pipe';
import { InLakhsPipe } from './inlakhs.pipe';
import { UpperCasePipe } from './upperCase.pipe'
import { ShowErrorsComponent } from './showError.component';
import { SumByColumnPipe } from './sumbycolumn.pipe';
import { OrderByDatePipe } from './orderbyDate.pipe';
import { SharedUserComponent } from './sharedUser.component';
import { CommonHeaderComponent } from './commomHeader.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MapLastLocComponent } from './mapLastLoc.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  declarations: [
    NumberOnlyDirective,
    TextOnlyDirective,
    NavComponent,
    ConfirmDeleteComponent,
    ConfirmWindowComponent,
    menuView,
    ArraySortPipe,
    SortGridPipe,
    FilterPipe,
    ToSelectItemPipe,
    EqualValidator,
    AlphaNumericDirective,
    FormatTimePipe,
    CommaNumberOnlyDirective,
    MakeWordsPipe,
    CurrencyCodePipe,
    DayDiffPipe,
    InLakhsPipe,
    UpperCasePipe,
    ShowErrorsComponent,
    SumByColumnPipe,
    OrderByDatePipe,
    SharedUserComponent,
    CommonHeaderComponent,
    MapLastLocComponent
  ],

  providers: [
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    sharedNgModule,
    FormsModule,
    ReactiveFormsModule,
    sharedPrimengModule,
    RouterModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    ToastModule,
    // LeafletDrawModule,
    // LeafletModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBkKs4rGe4OJ9lVGxa94andW0DjCXlBBy4',
      // apiKey: 'AIzaSyAVsgN0Ou_BIY-3g9ZjCo4PIn_BIFeSZX0',
      libraries: ['places', 'geometry', 'drawing']
    }),
    ProgressbarModule,
    CarouselModule.forRoot()

  ],
  exports: [
    NavComponent,
    SharedUserComponent,
    NumberOnlyDirective,
    TextOnlyDirective,
    AgmCoreModule,
    // LeafletModule,
    // LeafletDrawModule,
    ConfirmWindowComponent,
    menuView,
    ArraySortPipe,
    SortGridPipe,
    FilterPipe,
    ToSelectItemPipe,
    EqualValidator,
    AlphaNumericDirective,
    FormatTimePipe,
    CommaNumberOnlyDirective,
    MakeWordsPipe,
    CurrencyCodePipe,
    DayDiffPipe,
    InLakhsPipe,
    UpperCasePipe,
    SumByColumnPipe,
    ShowErrorsComponent,
    OrderByDatePipe,
    CommonHeaderComponent,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    MapLastLocComponent,
    ProgressbarModule,
    CarouselModule
  ],
  entryComponents: [ConfirmDeleteComponent]
})

export class sharedModule { }
