import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class ConfigService {
    constructor(private httpClient: HttpClient) { }
    config() {
        return this.httpClient.get(`${environment.sil}`)
    }
}