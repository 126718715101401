import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyCode'
})

@Injectable()
export class CurrencyCodePipe implements PipeTransform {
    transform(input) {
        if (input == null || input == undefined || input == '') {
            return input;
        }
        var result = input.toString().split('.');

        var lastThree = result[0].substring(result[0].length - 3);
        var otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return output;
    }
}