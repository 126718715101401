import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap';

@NgModule({
        declarations: [

        ],
        providers: [BsModalService, BsModalRef],
        imports: [
                CommonModule,
                ModalModule.forRoot(),
                BsDropdownModule.forRoot(),
                AlertModule.forRoot(),
                BsDatepickerModule.forRoot(),
                TabsModule.forRoot()
        ],
        exports: [
                ModalModule,
                BsDropdownModule,
                AlertModule,
                BsDatepickerModule,
                TabsModule
        ]
})

export class sharedNgModule { }