import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class MastersService {
    token = JSON.parse(localStorage.getItem('currentUser')).token;
    companyID = JSON.parse(localStorage.getItem('currentUser')).companyID;
    headers = new HttpHeaders({
        'companyID': this.companyID,
        'Authorization': 'token ' + this.token
    });


    constructor(private http: HttpClient) { }
    //sub division 
    getSubdivisions() {
        return this.http.get(`${environment.apiUrl}/item/subdivision/list?limit=100&sortBy={"name":"asc"}`, { headers: this.headers });
    }
    getAddress(lat, lng) {
        var url = 'https://nominatim.openstreetmap.org/reverse?format=jsonv2'
        return this.http.get(`${url}&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`, { headers: this.headers })
    }
    createSubDivision(obj) {
        return this.http.post(`${environment.apiUrl}/item/subdivision/create`, obj, { headers: this.headers });
    }

    updateSubDivision(obj) {
        return this.http.put(`${environment.apiUrl}/item/subdivision/${obj.id}`, obj, { headers: this.headers });
    }

    deleteSubDivision(id) {
        return this.http.delete(`${environment.apiUrl}/item/subdivision/${id}`, { headers: this.headers });
    }
    //ps

    getPsList() {
        return this.http.get(`${environment.apiUrl}/item/policestation/list?limit=1000&sortBy={"psName":"asc"}`, { headers: this.headers });
    }
    getPs(id) {
        return this.http.get(`${environment.apiUrl}/item/policestation/list?limit=1000&sortBy={"psName":"asc"}&circleID=${id}`, { headers: this.headers });

    }
    createPS(obj) {
        return this.http.post(`${environment.apiUrl}/item/policestation/create`, obj, { headers: this.headers });
    }

    updatePS(obj) {
        return this.http.put(`${environment.apiUrl}/item/policestation/${obj.id}`, obj, { headers: this.headers });
    }

    deletePS(id) {
        return this.http.delete(`${environment.apiUrl}/item/policestation/${id}`, { headers: this.headers });
    }

    //mandal

    getMandals() {
        return this.http.get(`${environment.apiUrl}/item/mandal/list?limit=1000`, { headers: this.headers });
    }

    createMandal(obj) {
        return this.http.post(`${environment.apiUrl}/item/mandal/create`, obj, { headers: this.headers });
    }

    updateMandal(obj) {
        return this.http.put(`${environment.apiUrl}/item/mandal/${obj.id}`, obj, { headers: this.headers });
    }

    deleteMandal(id) {
        return this.http.delete(`${environment.apiUrl}/item/mandal/${id}`, { headers: this.headers });
    }

    //circles
    getCircles(id) {
        return this.http.get(`${environment.apiUrl}/item/circle/list?divisionID=${id}`, { headers: this.headers });
    }

    createCircle(obj) {
        return this.http.post(`${environment.apiUrl}/item/circle/create`, obj, { headers: this.headers });
    }

    updateCircle(obj) {
        return this.http.put(`${environment.apiUrl}/item/circle/${obj.id}`, obj, { headers: this.headers });
    }

    deleteCircle(id) {
        return this.http.delete(`${environment.apiUrl}/item/circle/${id}`, { headers: this.headers });
    }

    //blue colts

    getBlueColts(id) {
        return this.http.get(`${environment.apiUrl}/item/bluecolts/list?psID=${id}`, { headers: this.headers });
    }

    createBlueColts(obj) {
        return this.http.post(`${environment.apiUrl}/item/bluecolts/create`, obj, { headers: this.headers });
    }

    updateBlueColts(obj) {
        return this.http.put(`${environment.apiUrl}/item/bluecolts/${obj.id}`, obj, { headers: this.headers });
    }

    deleteBlueColts(id) {
        return this.http.delete(`${environment.apiUrl}/item/bluecolts/${id}`, { headers: this.headers });
    }

    //villages

    getVillages(id) {
        return this.http.get(`${environment.apiUrl}/item/village/list?mandalID=${id}&sortBy={"name":"asc"}&limit=1000`, { headers: this.headers });
    }

    createVillage(obj) {
        return this.http.post(`${environment.apiUrl}/item/village/create`, obj, { headers: this.headers });
    }

    updateVillage(obj) {
        return this.http.put(`${environment.apiUrl}/item/village/${obj.id}`, obj, { headers: this.headers });
    }

    deleteVillage(id) {
        return this.http.delete(`${environment.apiUrl}/item/village/${id}`, { headers: this.headers });
    }

    getVehicles() {
        return this.http.get(`${environment.apiUrl}/item/dvmap/list?limit=1000&sortBy={"vehicleName":"asc"}`, { headers: this.headers });
    }


    getVehiclesNew(id) {
        return this.http.get(`${environment.apiUrl}/item/dvmap/list?limit=100&psID=${id}`, { headers: this.headers })
    }
    //Device Mapping 

    getDeviceMappingList(ps) {
        let url = `${environment.apiUrl}/item/dvmap/list?limit=1000`
        if (ps) {
            url = url + `&psName=${ps}`
        }
        return this.http.get(url, { headers: this.headers });
    }

    getDeviceTypes() {
        return this.http.get(`${environment.apiUrl}/item/vehicle/list?limit=1000`, { headers: this.headers });
    }
    getDeviceTypesList() {

    }
    getGroupNames() {
        return this.http.get(`${environment.apiUrl}/item/group/list?limit=1000`, { headers: this.headers });
    }

    createDeviceMapping(obj) {
        return this.http.post(`${environment.apiUrl}/item/dvmap/create`, obj, { headers: this.headers });
    }

    updateDeviceMapping(obj) {
        return this.http.put(`${environment.apiUrl}/item/dvmap/${obj.id}`, obj, { headers: this.headers });
    }

    deleteDeviceMapping(id) {
        return this.http.delete(`${environment.apiUrl}/item/dvmap/${id}`, { headers: this.headers });
    }

    getAllCircles(obj) {
        return this.http.get(`${environment.apiUrl}/item/circle/list?limit=1000`, { headers: this.headers, params: obj });
    }

    getIncidentConsolidateReports(obj) {
        return this.http.get(`${environment.apiUrl}/eraksha/incident/reportbyheirarchy`, { headers: this.headers, params: obj });
    }

    getICRExcel(obj) {
        return this.http.get(`${environment.apiUrl}/eraksha/incident/incidentpswiseexcel`, {
            headers: {
                'Content-type': 'text/html',
                'companyID': this.companyID,
                'Authorization': 'token ' + this.token
            }, params: obj
        });
    }

    getICRPDF(obj) {
        return this.http.get(`${environment.apiUrl}/eraksha/incident/incidentpswisepdf`, {
            headers: {
                'Content-type': 'application/pdf',
                'companyID': this.companyID,
                'Authorization': 'token ' + this.token
            }, params: obj,
            responseType: 'arraybuffer'
        });
    }

    getIncidentNatures(obj) {
        return this.http.get(`${environment.apiUrl}/item/incidentnature/list`, {
            headers: this.headers, params: obj
        });
    }

    getAllPS(obj) {
        return this.http.get(`${environment.apiUrl}/item/policestation/list?limit=1000`, { headers: this.headers, params: obj });
    }

    getIRFList(obj) {
        return this.http.get(`${environment.apiUrl}/item/incident/list`, { headers: this.headers, params: obj });
    }

    uploadFile(file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.put(`${environment.apiUrl}/commons/uploadfile`, formData, { headers: this.headers });
    }

    createIRF(obj) {
        return this.http.post(`${environment.apiUrl}/eraksha/incident/create`, obj, { headers: this.headers });
    }

    updateIRF(irf) {
        return this.http.put(`${environment.apiUrl}/item/incident/${irf.id}`, irf, { headers: this.headers });
    }
    // Dashboard services

    placesOfInterest(type: any, sortName: any) {
        return this.http.get(`${environment.apiUrl}/item/${type}/list?limit=1000`, { headers: this.headers })
    }
    getAmbulances() {
        return this.http.get(`${environment.apiUrl}/eraksha/getAmbulance`, { headers: this.headers })
    }
    getFireStation() {
        return this.http.get(`${environment.apiUrl}/eraksha/getFireStation`, { headers: this.headers })
    }
    getCameras() {
        return this.http.get(`${environment.apiUrl}/eraksha/getCamara`, { headers: this.headers })
    }
    getLoBeats() {
        return this.http.get(`${environment.apiUrl}/item/LOBEATS/list`, { headers: this.headers })
    }
    getDigitalBeats(obj) {
        let url = `${environment.apiUrl}/item/offenders/list?limit=${obj.limit}`;
        if (obj.psName) {
            url = url + `&psName=${obj.psName}`;
        }
        return this.http.get(url, { headers: this.headers })

    }

    getVehicleDetails(obj) {
        return this.http.get(`${environment.apiUrl}/item/dvmap/list`, { headers: this.headers, params: obj });
    }

    updateCIF(obj, id) {
        return this.http.put(`${environment.apiUrl}/item/incident/${id}`, obj, { headers: this.headers });

    }

    //settings

    getSettings(obj) {
        return this.http.get(`${environment.apiUrl}/item/SETTINGS/list?limit=100`, { headers: this.headers, params: obj });
    }

    updateSettings(obj) {
        return this.http.put(`${environment.apiUrl}/eraksha/updatesettings`, obj, { headers: this.headers, params: { 'type': 'SETTINGS' } });
    }

    getSOSNotifs(obj) {
        return this.http.get(`${environment.apiUrl}/commons/notification/list`, { headers: this.headers, params: obj });
    }

    getSOSList() {
        return this.http.get(`${environment.apiUrl}/item/panic/list?limit=100`, { headers: this.headers });
    }
}