import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattime'
})

@Injectable()
export class FormatTimePipe implements PipeTransform {
  public transform(timeInNumber: any): any {
    const inputValue = timeInNumber;
    if (!timeInNumber || timeInNumber === '-' || timeInNumber === 'NA') {
      return timeInNumber;
    }
    if (inputValue < 0) {
      timeInNumber = timeInNumber * -1;
    }
    let seconds = Math.floor(timeInNumber / 1000) % 60;
    let minutes = Math.floor(timeInNumber / (1000 * 60) % 60);
    let hours = Math.floor(timeInNumber / (1000 * 60 * 60));
    if (inputValue < 0) {
      return ("-" + hours + "h")
        + ":" + (minutes < 10 ? "0" + minutes + "m" : minutes + "m")
        + ":" + (seconds < 10 ? "0" + seconds + "s" : seconds + "s");
    } else {
      if (hours > 0) {
        return ((hours < 10) ? "0" + hours + "h" : hours + "h")
          + ":" + (minutes < 10 ? "0" + minutes + "m" : minutes + "m")
          + ":" + (seconds < 10 ? "0" + seconds + "s" : seconds + "s");
      }
      else {
        return (minutes < 10 ? "0" + minutes + "m" : minutes + "m")
          + ":" + (seconds < 10 ? "0" + seconds + "s" : seconds + "s");
      }
    }
  }
}
