import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Pipe({
    name: 'toSelectItem'
})

@Injectable()
export class ToSelectItemPipe implements PipeTransform {
        public transform(people: any, item:string, labelItem:string): SelectItem[] {
            if (!people) return undefined;
            if(!labelItem){
                labelItem=item;
            }
            return people.map(p => ({ label: p[item], value:p[labelItem] }));
          }
    
}