import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { MastersService } from 'src/_services/masters.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/_services/common.service';

@Component({
    selector: 'app-commonHeader',
    templateUrl: './commonHeader.component.html',
    providers: [MessageService]
})
export class CommonHeaderComponent implements OnInit {

    fromDate: any;
    toDate: any;
    obj: any;
    subDivisionList: any;
    error: string = "Network Error. Please try again."
    circles: any;
    psList: any;
    showsubdiv: any;
    showcircle: any;
    showps: any;
    showclear: any;
    dclass: any;
    onlydates: any;

    @Output() headerEvent = new EventEmitter();
    match: any;
    psparams: any;
    psName: any;
    showpsclear: any;

    constructor(private _toast: MessageService, private masterService: MastersService,
        private router: Router, private commonService: CommonService) {

    }

    ngOnInit() {
        this.match = this.commonService.getmatch({});
        if (this.match.psID)
            this.psName = JSON.parse(localStorage.getItem('currentUser')).extras.psName;
        this.getPsList();
        let dt = new Date(moment(new Date(), 'MM/DD/YYYY').startOf('day').format());
        this.fromDate = new Date(dt);
        this.toDate = new Date();
        this.preCheck();
    }

    preCheck() {
        let urls = this.router.url.split('/');
        let url = urls[urls.length - 1];
        if (url == "incidentConsolidatedReport") {
            this.showsubdiv = this.showps = this.showcircle = this.showclear = true;
            this.onlydates = false;
            this.dclass = "form-group col-md-2 col-3"
            this.getSubDivisions();
            this.getPSCircle();
            this.getReports();
        }
        else if (url == "vehicleAlertsReport" || url == "distance") {
            this.showsubdiv = this.showps = this.showcircle = this.showclear = false;
            this.onlydates = true;
            this.dclass = "form-group col-md-4 row";
            this.getReports();
        }
    }

    getReports() {
        let from = new Date(this.fromDate);
        let to = new Date(this.toDate);
        if (from.getTime() - to.getTime() > 0) {
            this.showToast('error', 'Invalid Date Range');
            return;
        }

        this.obj = {
            from: from.toISOString(),
            to: to.toISOString(),
            subDivision: this.match.subDivID,
            circle: this.match.circleID,
            ps: this.match.psID,
            psName: this.psName
        }

        this.headerEvent.emit(this.obj);
    }

    showToast(type, msg) {
        this._toast.add({ severity: type, summary: msg });
    }

    getSubDivisions() {
        this.subDivisionList = this.circles = this.psList = [];
        this.masterService.getSubdivisions().subscribe(
            data => {
                if (data['status'] == 'success') {
                    if (this.match.subDivID) {
                        this.subDivisionList = data['rows'].filter(i => i.id == this.match.subDivID);
                    }
                    else {
                        this.subDivisionList = data['rows'];
                    }
                } else {
                    this.showToast('warn', 'Error getting Sub Division List.');
                }
            },
            error => {
                this.showToast('error', this.error);
            })
    }

    getPSCircle() {
        this.circles = [];
        let obj;
        obj = {
            sortBy: JSON.stringify({ 'name': 'asc' })
        }
        if (this.match.subDivID) {
            obj = {
                sortBy: JSON.stringify({ 'name': 'asc' }),
                divisionID: this.match.subDivID
            }
        }
        this.masterService.getAllCircles(obj).subscribe(data => {
            if (data['status'] == 'success') {
                if (this.match.circleID) {
                    this.circles = data['rows'].filter(i => i.id == this.match.circleID);
                }
                else {
                    this.circles = data['rows'];
                }
            }
            else {
                this.showToast('warn', 'Error getting Circle List.');
            }
        },
            error => {
                this.showToast('error', this.error);

            })
    }

    getPsList() {
        this.psList = [];
        this.psparams = {
            sortBy: JSON.stringify({ 'psName': 'asc' })
        }
        if (this.match.subDivID) {
            this.psparams.subDivID = this.match.subDivID;
        }
        if (this.match.circleID) {
            this.psparams.circleID = this.match.circleID;
        }
        this.masterService.getAllPS(this.psparams).subscribe(data => {
            if (data['status'] == 'success') {
                if (this.match.psID) {
                    this.psList = data['rows'].filter(i => i.id == this.match.psID);
                    this.showpsclear = false;
                    this.psName = this.psList[0].psName;
                }
                else {
                    this.psList = data['rows'];
                    this.showpsclear = true;
                }
            } else {
                this.psName = null;
                this.showToast('warn', 'Error getting Police station List.');
            }
        },
            error => {
                this.psName = null;
                this.showToast('error', this.error);
            })
    }

    clear() {
        this.match = this.commonService.getmatch({});
        this.getSubDivisions();
        this.getPSCircle();
        this.getPsList();
        this.getReports();
    }

    clickedPS() {
        if (!this.psName) {
            this.getReports();
        }
    }

}
