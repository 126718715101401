import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/_services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  companyData:any;
  companies:any;
  s3_bucketurl: any;
  stateLogo:any;
  logo_url:any;
  state_police_Logo:any;
  background_url:any;
  constructor(private configService:ConfigService,private router:Router) { }

  ngOnInit() {
    this.getConfig();
    localStorage.clear();
  }
  getConfig() {
    this.configService.config().subscribe(
      data => {
        console.log(data);
        this.companyData  = data;
        this.s3_bucketurl = this.companyData.s3_bucketurl;
        this.stateLogo = this.companyData.stateLogo;
        this.logo_url = this.companyData.logo_url;
        this.state_police_Logo  = this.companyData.state_police_Logo;
        this.background_url = this.s3_bucketurl+this.companyData.background_url;
        this.companies = Object.values(this.companyData.companys);
      }
    )
  }
  navToLogin(company) {
    console.log(company);
    localStorage.setItem('company',JSON.stringify(company));
    this.router.navigate(['/login'])
  }
}
