import { Directive, ElementRef, HostListener } from '@angular/core';
import { isNumber } from 'util';

@Directive({
    selector: '[alphanumericOnly]'
})

export class AlphaNumericDirective {
    // Allow decimal numbers and negative values new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    private regex: RegExp = new RegExp(/^[a-zA-Z0-9]+$/g, '');
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<any> = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    //  private specialKeys: Array<number> = ['1E', 26, 20, 35, 34];
    constructor(private el: ElementRef) {
    }
    @HostListener('keyup', ['$event'])
    onkeyup(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase()
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key.toUpperCase());
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }


    }
}